<template>
  <div :class="className">
    <van-nav-bar
      title="巡检管理"
      :border="false"
      left-arrow
      @click-left="backHome"
    >
    </van-nav-bar>
    <van-list :class="`${className}__list`">
      <van-cell
        v-for="(item, idx) in recordsConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        :class="`${className}__list-item`"
        :to="{ name: item.routerName, query: item.routerQuery || {} }"
        :border="idx !== recordsConf.length - 1"
      >
        <template #title>
          <div class="title-container">
            <img class="cell-icon" :src="item.icon" alt="" />
            <span>{{ item.title }}</span>
            <i
              v-if="item.count !== 0"
              :class="['badge', item.count < 10 ? '' : 'multi']"
              >{{ item.count }}</i
            >
          </div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { getCountInspect } from "@/views/inspectionManagement/api";

export default {
  name: "InspectionManagement",
  data() {
    return {
      className: "inspection-management-dispatch",
      recordsConf: [
        {
          icon: require("../../assets/images/inspectionManagement/approve.png"),
          title: "待审批",
          routerName: "inspectionNew",
          count: 0,
          routerQuery: { type: 5 }
        },
        {
          icon: require("../../assets/images/inspectionManagement/todo.png"),
          title: "待我执行",
          routerName: "inspectionNew",
          count: 0,
          routerQuery: { type: 1 }
        },
        {
          icon: require("../../assets/images/inspectionManagement/rerecord.png"),
          title: "待补录",
          // routerName: "inspectionRecordTranscribe",
          routerName: "inspectionNew",
          count: 0,
          routerQuery: { type: 2 }
        },
        {
          icon: require("../../assets/images/inspectionManagement/done.png"),
          title: "已执行",
          // routerName: "inspectionRecordDone",
          routerName: "inspectionNew",
          count: 0,
          routerQuery: { type: 3 }
        },
        {
          icon: require("../../assets/images/inspectionManagement/record.png"),
          title: "巡检记录",
          // routerName: "inspectRecordList",
          routerName: "inspectionAll",
          count: 0,
          routerQuery: { type: 4 }
        }
      ]
    };
  },
  created() {
    let params = {
      executePersonId: this.userInfo.id,
      typeFlag: 1
    };
    getCountInspect(params).then(res => {
      this.recordsConf[1].count = res?.waitMeExecute ?? 0;
      this.recordsConf[2].count = res?.waitMeSupplement ?? 0;
    });
  },
  methods: {
    backHome() {
      this.$router.replace({ name: "Home" });
    }
  }
};
</script>

<style lang="scss">
.inspection-management-dispatch {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }
  &__list-item {
    padding: 15px 20px;
    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }
    .title-container {
      width: 100%;
      position: relative;
      span {
        color: #2e2e4d;
        font-size: 15px;
      }
      .cell-icon {
        width: 24px;
        height: 24px;
        margin-right: 6px;
        position: relative;
        top: 6px;
      }
      .badge {
        text-align: center;
        font-size: 11px;
        line-height: 15px;
        display: inline-block;
        position: absolute;
        top: 4px;
        right: 10px;
        min-width: 14px;
        height: 14px;
        color: #fff;
        background: #f53f3f;
        border-radius: 7px;
        &.multi {
          padding: 0 4px;
        }
      }
    }
  }
}
</style>
